<template>
  <div>
    <v-card class="my-4">
      <v-card-title
        >{{ name }} Routine
        <v-spacer></v-spacer>
        <v-btn @click="isFormOpen = !isFormOpen">Add Movement</v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="routineMovements">
        <template #[`item.actions`]="{ item }">
          <v-btn @click="deleteRoutineMovement(item.id)" icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="isFormOpen" max-width="600px" persistent>
      <v-card>
        <v-card-title>New Routine Movement</v-card-title>
        <v-card-text>
          <v-form>
            <v-autocomplete
              label="Movment"
              v-model="newRoutineMovement.movement_id"
              :items="movementOptions"
            ></v-autocomplete>
            <div class="d-flex">
              <v-text-field
                class="mr-3"
                type="number"
                label="sets"
                v-model="newRoutineMovement.sets"
              ></v-text-field>
              <v-text-field
                class="ml-3"
                type="number"
                label="reps"
                v-model="newRoutineMovement.reps"
              ></v-text-field>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isFormOpen = false" text>close</v-btn>
          <v-btn @click="saveNewRoutineMovement" text color="primary"
            >save</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
const emptyRoutine = {
  movement_id: null,
  sets: null,
  reps: null,
};
import repprApi from "../services/repprApi";
export default {
  data() {
    return {
      isFormOpen: false,
      newRoutineMovement: {
        ...emptyRoutine,
      },
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "Movement.name" },
        { text: "Sets", value: "sets" },
        { text: "Reps", value: "reps" },
        { text: "", value: "actions", sortable: false },
      ],
      routineMovements: [],
      name: "",
      movementOptions: [],
    };
  },
  async created() {
    const routine = await repprApi.getRoutineById(this.routineId);
    this.name = routine.name;
    const routineMovements = await repprApi.getRoutineMovements(this.routineId);
    console.log(routineMovements);
    this.routineMovements = routineMovements;
    const movements = await repprApi.getMovements();
    this.movementOptions = movements.map((movement) => {
      const { name, id } = movement;
      return { text: name, value: id };
    });
  },
  computed: {
    routineId() {
      return this.$route.params.id;
    },
  },
  methods: {
    async saveNewRoutineMovement() {
      const routineMovement = await repprApi.createRoutineMovement(
        this.routineId,
        this.newRoutineMovement
      );
      this.routineMovements = [...this.routineMovements, routineMovement];
      this.newRoutineMovement = { ...emptyRoutine };
      this.isFormOpen = false;
    },
    async deleteRoutineMovement(routineMovementId) {
      await repprApi.deleteRoutineMovement(routineMovementId);
      this.routineMovements = this.routineMovements.filter(
        ({ id }) => id != routineMovementId
      );
    },
  },
};
</script>