<template>
  <div>
    <v-card>
      <v-card-title>
        Routines
        <v-spacer></v-spacer>
        <v-btn @click="isFormOpen = true">New Routine</v-btn>
      </v-card-title>
      <v-data-table :headers="headers" :items="routines">
        <template #[`item.name`]="{ item }">
          <router-link :to="{ name: 'Routine', params: { id: item.id } }">{{
            item.name
          }}</router-link>
        </template>
        <template #[`item.createdAt`]="{ item }">
          <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="isFormOpen" max-width="600px" persistent>
      <v-card>
        <v-card-title>New Routine</v-card-title>
        <v-card-text>
          <v-text-field
            label="Routine Name"
            v-model="newRoutineName"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isFormOpen = false" text>close</v-btn>
          <v-btn @click="saveRoutine" text color="primary">save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import repprApi from "../services/repprApi";
export default {
  async created() {
    this.routines = await repprApi.getRoutines();
  },
  data() {
    return {
      isFormOpen: false,
      newRoutineName: "",
      routines: [],
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Created At", value: "createdAt" },
      ],
    };
  },
  methods: {
    async saveRoutine() {
      const routine = await repprApi.createRoutine({
        name: this.newRoutineName,
      });
      this.newRoutineName = "";
      this.isFormOpen = false;
      this.routines = [...this.routines, routine];
    },
  },
};
</script>