<template>
  <div>
    <v-card class="text-center py-6" min-width="320">
      <v-card-title class="justify-center display-1 mb-2"
        ><reppr-text-logo></reppr-text-logo
      ></v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            outlined
            label="Email"
            type="email"
            v-model="email"
            @keyup.enter="submitLogin"
            dense
          ></v-text-field>
          <v-text-field
            outlined
            label="Password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            dense
            @keyup.enter="submitLogin"
            v-model="password"
            @click:append="showPassword = !showPassword"
          ></v-text-field>
          <v-btn color="primary" block @click="submitLogin">Sign In</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import repprApi from "../services/repprApi";
import RepprTextLogo from "../components/RepprTextLogo.vue";
export default {
  components: {
    RepprTextLogo,
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
    };
  },
  methods: {
    async submitLogin() {
      const loginRes = await repprApi.login(this.email, this.password);
      if (loginRes.token) {
        localStorage.setItem("user", loginRes.user);
        localStorage.setItem("jwt", loginRes.token);
        repprApi.setToken(loginRes.token);
        const { next } = this.$route.query;
        if (next) {
          this.$router.push(next);
        } else {
          this.$router.push("/");
        }
      }
    },
  },
};
</script>